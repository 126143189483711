@custom-media --small-viewport (width >=576px);
@custom-media --medium-small-viewport (width > 768px);
@custom-media --medium-viewport (width >=992px);
@custom-media --large-viewport (width >=1200px);

.glightbox-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999 !important;
  overflow: hidden;
  touch-action: none;
  text-size-adjust: 100%;
  backface-visibility: hidden;
  outline: none;
  overflow: hidden;

  &.inactive {
    display: none;
  }

  & .gcontainer {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 9999;
    overflow: hidden;
  }

  .gslider {
    transition: transform 0.4s ease;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex !important;
    justify-content: center;
    align-items: center;
    transform: translate3d(0, 0, 0);
  }

  .gslide {
    width: 100%;
    position: absolute;
    opacity: 1;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;

    &.current {
      opacity: 1;
      z-index: 99999;
      position: relative;
    }

    &.prev {
      opacity: 1;
      z-index: 9999;
    }
  }

  .gslide-inner-content {
    width: 100%;
  }

  .ginner-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 100%;
    margin: auto;
    height: 100vh;

    &.gvideo-container {
      width: 100%;
    }

    @media (--medium-small-viewport) {
      width: auto;
      height: auto;
      flex-direction: row;
    }

    &.desc-bottom,
    &.desc-top {
      flex-direction: column;
    }

    &.desc-left,
    &.desc-right {
      max-width: 100% !important;
    }

    &.desc-top {
      @media (--medium-small-viewport) {
        .gslide-description {
          order: 0;
        }
        .gslide-image,
        .gslide-image img {
          order: 1;
        }
      }
    }

    &.desc-left {
      @media (--medium-small-viewport) {
        .gslide-description {
          order: 0;
        }
        .gslide-image {
          order: 1;
        }
      }
    }
  }
}

.gslide {
  iframe,
  video {
    outline: none !important;
    border: none;
    min-height: 165px;
    -webkit-overflow-scrolling: touch;
    touch-action: auto;
  }
}

.gslide:not(.current) {
  pointer-events: none;
}

.gslide-image {
  align-items: center;

  img {
    max-height: 100vh;
    display: block;
    padding: 0;
    float: none;
    outline: none;
    border: none;
    user-select: none;
    max-width: 100vw;
    width: auto;
    height: auto;
    object-fit: cover;
    touch-action: none;
    margin: auto;
    min-width: 200px;

    @media (--medium-small-viewport) {
      max-height: 97vh;
      max-width: 100%;
    }

    .desc-top &,
    .desc-bottom & {
      width: auto;
    }

    .desc-left &,
    .desc-right & {
      width: auto;
      max-width: 100%;
    }

    &.zoomable {
      position: relative;

      @media (--medium-small-viewport) {
        cursor: zoom-in;

        .zoomed & {
          cursor: grab;
        }
      }
    }

    &.dragging {
      cursor: grabbing !important;
      transition: none;
    }
  }
}

.gslide-video {
  position: relative;
  max-width: 100vh;
  width: 100% !important;

  .gvideo-wrapper {
    width: 100%;
    /* max-width: 160vmin; */
    margin: auto;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 0, 0, 0.34);
    display: none;
  }
  &.playing::before {
    display: none;
  }

  &.fullscreen {
    max-width: 100% !important;
    min-width: 100%;
    height: 75vh;

    video {
      max-width: 100% !important;
      width: 100% !important;
    }
  }
}

.gslide-inline {
  background: #fff;
  text-align: left;
  max-height: calc(100vh - 40px);
  overflow: auto;
  max-width: 100%;

  @media (--medium-small-viewport) {
    max-height: 95vh;
  }

  .ginlined-content {
    padding: 20px;
    width: 100%;
  }

  .dragging {
    cursor: grabbing !important;
    transition: none;
  }
}

.ginlined-content {
  overflow: auto;
  display: block !important;
  opacity: 1;
}

.gslide-external {
  display: flex;
  width: 100%;
  min-width: 100%;
  background: #fff;
  padding: 0;
  overflow: auto;
  max-height: 75vh;
  height: 100%;

  @media (--medium-small-viewport) {
    max-height: 100vh;
  }
}

.gslide-media {
  display: flex;
  width: auto;
  margin: 0 auto;

  .zoomed & {
    box-shadow: none !important;
  }

  .desc-top &,
  .desc-bottom & {
    margin: 0 auto;
    flex-direction: column;
  }
}

.gslide-description {
  position: relative;
  flex: 1 0 100%;

  &.description-left,
  &.description-right {
    max-width: 100%;

    @media (--medium-small-viewport) {
      max-width: 275px;
    }
  }

  &.description-bottom,
  &.description-top {
    margin: 0 auto;
    width: 100%;
  }

  p {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .zoomed & {
    display: none;
  }
}

.glightbox-button-hidden {
  display: none;
}

/*
 * Description for mobiles
 * something like facebook does the description
 * for the photos
*/
.glightbox-mobile .glightbox-container {
  .gslide-description {
    height: auto !important;
    width: 100%;
    background: transparent;
    position: absolute;
    bottom: 0;
    padding: 19px 11px;
    max-width: 100vw !important;
    order: 2 !important;
    max-height: 78vh;
    overflow: auto !important;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
    transition: opacity 0.3s linear;
    padding-bottom: 50px;
  }

  .gslide-title {
    color: #fff;
    font-size: 1em;
  }
  .gslide-desc {
    color: #a1a1a1;

    a {
      color: #fff;
      font-weight: bold;
    }

    * {
      color: inherit;
    }
    string {
      color: #fff;
    }
    .desc-more {
      color: #fff;
      opacity: 0.4;
    }
  }
}

.gdesc-open {
  .gslide-media {
    transition: opacity 0.5s ease;
    opacity: 0.4;
  }
  .gdesc-inner {
    padding-bottom: 30px;
  }
}
.gdesc-closed {
  .gslide-media {
    transition: opacity 0.5s ease;
    opacity: 1;
  }
}

.greset {
  transition: all 0.3s ease;
}

.gabsolute {
  position: absolute;
}

.grelative {
  position: relative;
}

.glightbox-desc {
  display: none !important;
}

.glightbox-open {
  overflow: hidden;

  @media (--medium-small-viewport) {
    height: auto;
  }
}

.gloader {
  height: 25px;
  width: 25px;
  animation: lightboxLoader 0.8s infinite linear;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  display: block;
  z-index: 9999;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 47%;
}

.goverlay {
  width: 100%;
  height: calc(100vh + 1px);
  position: fixed;
  top: -1px;
  left: 0;
  background: #000;
  will-change: opacity;

  .glightbox-mobile & {
    background: #000;
  }

  @media (--medium-small-viewport) {
    background: rgba(0, 0, 0, 0.92);
  }

  @media screen and (max-height: 420px) {
    background: #000;
  }
}

.gprev,
.gnext,
.gclose {
  z-index: 99999;
  cursor: pointer;
  width: 26px;
  height: 44px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  svg {
    display: block;
    width: 25px;
    height: auto;
    margin: 0;
    padding: 0;
  }

  &.disabled {
    opacity: 0.1;
  }

  .garrow {
    stroke: #fff;
  }
}

.gbtn.focused {
  outline: 2px solid #0f3d81;
}

iframe.wait-autoplay {
  opacity: 0;
}

.glightbox-closing {
  .gnext,
  .gprev,
  .gclose {
    opacity: 0 !important;
  }
}

/*Skin */
.glightbox-clean {
  .gslide-media {
    @media (--medium-small-viewport) {
      box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.65);
    }
  }

  .gslide-description {
    background: #fff;
  }

  .gdesc-inner {
    padding: 22px 20px;
  }

  @media (--medium-small-viewport) {
    .description-left,
    .description-right {
      .gdesc-inner {
        position: absolute;
        height: 100%;
        overflow-y: auto;
      }
    }
  }

  .gslide-title {
    font-size: 1em;
    font-weight: normal;
    font-family: arial;
    color: #000;
    margin-bottom: 19px;
    line-height: 1.4em;
  }

  .gslide-desc {
    font-size: 0.86em;
    margin-bottom: 0;
    font-family: arial;
    line-height: 1.4em;
  }

  .gslide-video {
    background: #000;
  }

  .gprev,
  .gnext,
  .gclose {
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 4px;

    @media (--medium-small-viewport) {
      background-color: rgba(0, 0, 0, 0.32);

      &:hover {
        background-color: rgba(0, 0, 0, 0.7);
      }
    }

    path {
      fill: #fff;
    }
  }

  .gprev {
    position: absolute;
    top: -100%;
    left: 30px;
    width: 40px;
    height: 50px;

    @media (--medium-small-viewport) {
      top: 45%;
    }
  }

  .gnext {
    position: absolute;
    top: -100%;
    right: 30px;
    width: 40px;
    height: 50px;

    @media (--medium-small-viewport) {
      top: 45%;
    }
  }

  .gclose {
    width: 35px;
    height: 35px;
    top: 15px;
    right: 10px;
    position: absolute;

    svg {
      width: 18px;
      height: auto;
    }

    @media (--medium-viewport) {
      opacity: 0.7;
      right: 20px;
    }

    &:hover {
      opacity: 1;
    }
  }
}

/*CSS Animations*/
.gfadeIn {
  animation: gfadeIn 0.5s ease;
}
.gfadeOut {
  animation: gfadeOut 0.5s ease;
}
.gslideOutLeft {
  animation: gslideOutLeft 0.3s ease;
}
.gslideInLeft {
  animation: gslideInLeft 0.3s ease;
}
.gslideOutRight {
  animation: gslideOutRight 0.3s ease;
}
.gslideInRight {
  animation: gslideInRight 0.3s ease;
}
.gzoomIn {
  animation: gzoomIn 0.5s ease;
}
.gzoomOut {
  animation: gzoomOut 0.5s ease;
}

@keyframes lightboxLoader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes gfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes gfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes gslideInLeft {
  from {
    opacity: 0;
    transform: translate3d(-60%, 0, 0);
  }
  to {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    transform: translate3d(60%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(60%, 0, 0);
    opacity: 0;
  }
}
@keyframes gzoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}
@keyframes gzoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
